import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "./LoginStyle";
import { authClient } from "Auth/authClient";
import { createLoginMonitor } from "../../Utilities/LoginManager";
import ActionCheckbox from "components/ActionCheckbox/ActionCheckbox";
import { RES_STATUS_OK } from "config/constant";

export const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [personEmail, setPersonEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const loginMonitor = createLoginMonitor();

  const [errMsg, setErrMsg] = useState("");

  const handleNext = async () => {

    var dirs = (window.location.pathname).split('/');
    const response = await authClient(personEmail, password)
    if (response["result-status"] === RES_STATUS_OK) {
      const client_id = response["result-object"]["clientId"]
      const user_key = response["result-object"]["user"]["id"]
      const token = response["result-object"]["token"]
      localStorage.setItem("client_id", client_id);
      localStorage.setItem("user_key", user_key);
      localStorage.setItem("token", token);
      loginMonitor.login();
    } else {
      setErrMsg(response["message"]);
      return;
    }
    if (dirs[1] === "login") {
      window.location.replace("/")
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <div className={classes.loaderWrapper}>
        <div className={classes.modalRoot}>
          <div className={classes.title}>ログイン</div>
          <div className={classes.mainContainer}>
            <div className={classes.errorMessage}>{errMsg}</div>
            <div className={classes.form}>
              <div className={classes.formTitle}>メールアドレス</div>
              <ActionInput
                value={personEmail}
                action={(e) => setPersonEmail(e)}
                placeholder='example@shisaku-pad.co.jp'
              />
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>パスワード</div>
              <ActionInput
                value={password}
                type='password'
                action={(e) => setPassword(e)}
                placeholder='********'
              />
            </div>
            <div className={classes.rememberMe}>
              <ActionCheckbox
                type='checkbox'
                value='次回から自動的にログイン'
                checked={rememberMe}
                action={(e) => setRememberMe(e)} />
              次回から自動的にログイン
            </div>
          </div>
          <div className={classes.btnContainer}>
            <ActionButton
              content='キャンセル'
              className={classes.cancelBtn}
              action={() => { window.location.replace('/') }}
            />
            <ActionButton
              content='ログイン'
              type='dark'
              className={classes.confirmBtn}
              action={handleNext}
            />
          </div>
        </div>
      </div>
    </>
  );
};
