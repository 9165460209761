import { INVALID_ENCODING_PATTERNS } from "config/constant";

const validatePhone = (value: string) => {
  return !/^\d{2,4}\d{2,4}\d{4}$/.test(value) && !/^\d{2,4}-\d{2,4}-\d{4}$/.test(value)
}

const detectEncodingFailure = (value: string) => {
  return INVALID_ENCODING_PATTERNS.some((pattern: string) => value.includes(pattern));
}

export { validatePhone, detectEncodingFailure }
