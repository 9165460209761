import { getToken } from "Utilities/appHelper";
import { REQUEST_API_URL } from "../config/constant";

export async function ApiUser(data: any) {
    const token = getToken()
    const response: any = await fetch(REQUEST_API_URL + '/user', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data)
    })

    return response.json();
}

export async function ApiUserWithEmployeeId(data: any) {
    const token = getToken()
    const response: any = await fetch(REQUEST_API_URL + '/user_with_employee_id', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data)
    })

    return response.json();
}

export async function ApiPhoneUser(data: any) {
    const response: any = await fetch(REQUEST_API_URL + '/users_phone', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data)
    })

    return response.json();
}
