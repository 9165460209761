import { SidebarStyles } from "./SidebarStyles";
import LogoImg from "../../assets/images/logo-hz.png";
import default_avatar from "../../assets/images/icon_avatar.png";
import {
  ACCESS_REPORT_LINK,
  ACCOUNT_LINK,
  APPLICATION_REPORT_LINK,
  REQUEST_AVATAR_IMG_URL,
  SECURE_SITE_URL,
  SidbarData,
  SidebarItem,
} from "config/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import clsx from "clsx";

import { ApiAvatarImagesId } from "Apis/ApiAvatarImagesId";
import { ApiLogout } from "Apis/ApiLogout";
import { ApiClientsId } from "Apis/ApiClientsId";
import { ApiUsersIdNoLoginCheck } from "Apis/ApiUsersIdNoLoginCheck";
import { AfterApiIsLogin } from "Utilities/AuthUtility";

import { createLoginMonitor } from "Utilities/LoginManager";
import { fullNameOrFamilyName } from "Utilities/appHelper";

import { isEmpty } from "lodash";
import DropSideMenu from "components/DropSideMenu/DropSideMenu";
import { ApiUsersIdAsync } from "Apis/ApiUsersIdAsync";
import { Link } from "react-router-dom";

interface SidebarProps {
  className?: any;
  loginStatusForSidebar: boolean;
}

export default function Sidebar({
  className,
  loginStatusForSidebar,
}: SidebarProps) {
  const classes = SidebarStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const loginMonitor = createLoginMonitor();

  const initialUserValues = {
    id: "",
    client_id: "",
    last_name: "",
    first_name: "",
    full_name: "",
    phone: "",
    business_email: "",
    private_email: "",
    image_id: "",
  };

  const [userValues, setUserValues] = useState(initialUserValues);
  const [avatarURL, setAvatarURL] = useState();
  const [clientName, setClientName] = useState();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [loginStatusForSidebar]);

  async function init() {
    if (loginStatusForSidebar) {
      var response = await ApiUsersIdAsync(
        localStorage.getItem("user_key")
      );
      if (
        response["result-object"] !== null &&
        !(response["result-object"].id === void 0)
      ) {
        setUserData(response["result-object"]);
      } else {
        //if(loginMonitor.isLogin()) {
        if (localStorage.getItem("user_key") !== "") {
          handleLogout();
        }
      }
    }
  }

  async function setUserData(data: any) {
    setUserValues({
      ...userValues,
      id: data.id,
      client_id: data.client_id,
      last_name: data.last_name,
      first_name: data.first_name,
      full_name: data.full_name,
      phone: data.phone,
      business_email: data.business_email,
      private_email: data.private_email,
      image_id: data.image_id,
    });

    var url: any = default_avatar;
    setAvatarURL(url);
  }

  function loginCheck(data: any) {
    AfterApiIsLogin(data["result-status"]);
  }

  const handleLogout = async () => {
    loginMonitor.logout();
    window.location.replace("/");
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  console.log(location.pathname);
  return (
    <>
      <div className={clsx(classes.root, className)}>
        <Link to={"/"} className={classes.logoA}>
          <img
            src={LogoImg}
            className={classes.logoImg}
            alt="logo"
          //onClick={() => navigate("/")}
          />
        </Link>
        <div className={classes.avatarRoot}
        //onClick={() => window.open(SECURE_SITE_URL + ACCOUNT_LINK, '_blank')}
        >
          {
            /* loginMonitor.isLogin() ? */
            avatarURL ? (
              <>
                <img
                  src={avatarURL}
                  className={classes.avatarImg}
                  onError={(e) => {
                    const imgElement = e.target as HTMLImageElement;
                    imgElement.src = default_avatar;
                  }}
                />
              </>
            ) : (
              <></>
            )
          }
          <div className={classes.avatarName}>
            {fullNameOrFamilyName(
              userValues.full_name,
              userValues.first_name,
              userValues.last_name
            )}
          </div>
          <div className={classes.avatarJob}>{clientName}</div>
        </div>
        <div className={classes.logoutBtn} onClick={handleLogout}>
          {loginMonitor.isLogin() ? "ログアウト" : ""}
        </div>
        {loginMonitor.isLogin() ? (
          <>
            <div className={classes.sidebarContent}>
              {SidbarData.map((item: SidebarItem, key: number) => {
                // 階層をもつメニューはhoverで表示するようにする
                return !isEmpty(item.children) ? (
                  <div
                    className={classes.sidebarHoverItem}
                    key={key}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {isDropdownVisible && (
                      <DropSideMenu children={item.children!} />
                    )}
                    <div
                      className={clsx(
                        [ACCESS_REPORT_LINK, APPLICATION_REPORT_LINK].includes(
                          location.pathname
                        )
                          ? classes.ActivateItemRow
                          : classes.itemRow
                      )}
                    >
                      <img
                        src={item.img}
                        className={classes.itemImg}
                        alt="sidebar-icon"
                      />
                      <div className={classes.itemContent}>{item.content}</div>
                      <div className={classes.itemBtn}>
                        <i className="fas fa-caret-right"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.link}
                    target={item.link.indexOf("http") === 0 ? "_blank" : ""}
                    className={
                      location.pathname === item.link
                        ? classes.activeSidebarItem
                        : classes.sidebarItem
                    }
                    key={key}
                    rel="noreferrer"
                  //onClick={() => item.link.indexOf("http") === 0 ? window.open(item.link, '_blank') : navigate(item.link)}
                  >
                    <img
                      src={item.img}
                      className={classes.itemImg}
                      alt="sidebar-icon"
                    />
                    <div className={classes.itemContent}>{item.content}</div>
                    <div className={classes.itemBtn}>
                      <i className="fas fa-caret-right"></i>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
