import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
export const SidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      textAlign: "center",
      width: "300px",
      boxSizing: "border-box",
      background: "#FEEA99",
      padding: "48px 12px 48px 12px",
      height: "100%",
      zIndex: 3,
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      // overflowY: "scroll",
      [theme.breakpoints.down(840)]: {
        display: "none",
      },
    },
    logoA: {
      justifyContent: "center",
    },
    logoImg: {
      width: "167px",
      //height: "44px",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.5,
      },
      [theme.breakpoints.down(400)]: {
        width: "100px",
        height: "25px",
      },
    },
    avatarRoot: {
      marginTop: "24px",
      //marginBottom: "72px",
      marginBottom: "24px",
      textAlign: "center",
      cursor: "pointer",

      alignItems: "center",
      flexDirection: "column",
      "&:hover": {
        opacity: 0.5,
      },
    },
    avatarImg: {
      width: "64px",
      height: "64px",
      borderRadius: "50px",
      marginBottom: "12px",
    },
    avatarName: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      height: "16px",
    },
    avatarJob: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      height: "16px",
      marginTop: "12px",
      opacity: 0.5,
    },
    logoutBtn: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      fontWeight: 600,
      height: "16px",
      margin: "24px 0 24px",
      letterSpacing: "0.05em",
      lineHeight: "1.4",
      cursor: "pointer",
      [theme.breakpoints.down(540)]: {
        fontSize: "12px",
      },
      "&:hover": {
        opacity: 0.5,
      },
    },
    sidebarContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    sidebarItem: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "71px",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      margin: "0 12px",
      cursor: "pointer",
      borderBottom: "1px solid #fff",
      position: "relative",
      zIndex: 3,
      "&:hover": {
        opacity: 0.5,
      },
    },
    sidebarHoverItem: {
      width: "100%",
      padding: "0 12px",
      position: "relative",
    },
    itemRow: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "71px",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      cursor: "pointer",
      borderBottom: "1px solid #fff",
      zIndex: 3,
      "&:hover": {
        opacity: 0.5,
      },
    },
    ActivateItemRow: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "71px",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      cursor: "pointer",
      borderBottom: "1px solid #fff",
      zIndex: 3,
      opacity: 0.5,
    },
    activeSidebarItem: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "71px",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      margin: "0 12px",
      cursor: "pointer",
      opacity: 0.5,
      borderBottom: "1px solid #fff",
    },
    itemImg: {
      background: "rgba(0,0,0,0.0)",
      borderRadius: "12px",
      marginRight: "12px",
      width: "40px",
      height: "40px",
      minWidth: "40px",
      [theme.breakpoints.down(500)]: {
        margin: 0,
      },
    },
    itemContent: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 600,
      height: "auto",
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      padding: "0 12px",
      minWidth: "171.2px",
      boxSizing: "border-box",
      textAlign: "start",
      [theme.breakpoints.down(500)]: {
        minWidth: "133px",
      },
      [theme.breakpoints.down(400)]: {
        minWidth: "80px",
        fontSize: "12px",
      },
    },
    itemBtn: {
      "& i": {
        color: "#000",
        fontSize: "24px",
        height: "20px",
        width: "16px",
      },
    },
    studyLink: {
      position: "fixed",
      bottom: "20px",
      left: "20px",
      width: "auto",
      height: "28px",
      borderRadius: "4px",
      backgroundColor: "#000",
      boxShadow: "0 2px 4px rgb(0 0 0 / 14%)",
      zIndex: 2000,
      perspective: "300px",
      transition: "0s linear",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 12px",
      cursor: "pointer",
    },
    banner: {
      width: "97px",
      height: "19px",
    },
  })
);
