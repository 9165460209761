/** @format */

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FEF4CC",
      padding: "96px",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        padding: "96px 48px 96px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(540)]: {
        padding: "48px 24px 48px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    container: {
      fontFamily: "Noto Sans JP",
      background: "#fff",
      borderRadius: "10px",
      boxShadow: "40px 50px 30px rgb(0 0 0 / 10%)",
      padding: "24px",
      [theme.breakpoints.down(840)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: "12px",
      margin: "8px 0 24px"
    },
    loginRateContainer: {
      padding: "16px 20px",
      borderRadius: "8px",
      backgroundColor: "#FEF4CC",
      textAlign: "center",
    },
    loginRateTitle: {
      fontSize: "14px",
      fontWeight: 600,
    },
    loginRateLarge: {
      fontSize: "24px",
      fontWeight: 600,
    },
    loginRateSmall: {
      fontSize: "14px",
      fontWeight: 600,
    },
    reportTitle: {
      color: "#000",
      fontFamily: "Noto Sans JP",
      fontSize: "36px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      margin: "8px 0px 16px 0px",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "18px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    reportSmallTitle: {
      color: "#000",
      fontSize: "16px",
      fontWeight: 300,
      height: "auto",
      lineHeight: 1.4,
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    tableRoot: {},
    tableTool: {
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      color: "#000",
      fontSize: "14px",
      fontWeight: 600,
    },
    menuContainer: {
      marginTop: "6px",
    },
    radioBox: {
      display: "inline",
      marginRight: "6px",
    },
    formRadio: {},
    formRadioLabel: {
      fontSize: "16px",
    },
    outputCsv: {
      fontSize: "14px",
      fontWeight: 600,
      display: "inline-flex",
      alignItems: "center",
      gap: "4px",
      cursor: "pointer",
      padding: "4px 8px 4px 2px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
  })
);
