import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  displayNone: {
    display: "none",
  },
  modalRoot: {
    padding: "30px 35px",
    boxSizing: "border-box",
    width: "800px",
    height: "fit-content",
    background: "#fff",
    boxShadow: "0px 0px 30px rgba(55, 55, 79, 0.05)",
    borderRadius: "10px",
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down(769)]: {
      minWidth: "100% !important",
      width: "100% !important",
    },
  },
  form: {
    marginBottom: "10px",
    textAlign: "left",
  },
  /* formTitle: {
    minWidth: "100px",
    marginRight: "5px",
    color: "#000",
    fontFamily: "Noto Sans JP",//20230111
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    marginBottom: "10px",
  }, */
  formTitle: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: "0em",
    textAlign: "left",
    transitionDelay: "400ms",
    transitionDuration: "1000ms",
    marginBottom: "10px",
    [theme.breakpoints.down(440)]: {
      fontSize: "12px",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
    },
  },
  symbol: {
    color: "#f23a3c",
    fontFamily: "Noto Sans JP", //20230111
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: 1.4,
    marginRight: "5px",
  },
  formInput: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    textAlign: "center",
  },
  loaderWrapper: {
    position: "fixed",
    zIndex: 99998,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    overflowY: "scroll",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontStyle: "normal",
    //fontFamily: "Montserrat",
    fontFamily: "Noto Sans JP",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "50px",
    color: "#000",
  },
  container: {
    width: " 90%",
    margin: "0 auto",
  },
  mainContainerFlex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  mainContainer: {
    // padding: "0px 50px",
    [theme.breakpoints.down(540)]: {
      padding: 0,
    },
  },
  descContainer: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "29px",
    textAlign: "center",
    marginTop: "5px",
    //marginBottom: "30px",
    marginBottom: "10px",
  },
  descDetail: {
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    marginBottom: "20px",
  },
  anotation: {
    fontSize: "12px",
    color: "#f23a3c",
    fontWeight: 900,
  },
  beforeDownload: {
    marginBottom: "0px",
  },
  /* downloadBig: {
    textAlign: "left",
    lineHeight: "18px",
    "& a": {
      color: "#0066ff",
      textDecoration: "none",
    },
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.8,
    },
  }, */
  /* downloadSmall: {
    fontSize: "14px",
    display: "flex",
    marginBottom: "10px",
  }, */
  parentheses: {
    display: "inline",
    color: "#666666",
  },
  smallLink: {
    display: "inline-block",
    backgroundColor: "#FEEA99",
    padding: "12px 24px",
    borderRadius: "5px",
    marginRight: "40px",
    "& a": {
      color: "#000",
      textDecoration: "none",
      fontWeight: "bold",
    },
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.8,
    },
  },
  btnContainer: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(570)]: {
      display: "block",
    },
  },
  cancelBtn: {
    width: "100% !important",
    marginBottom: "20px",
    marginRight: "15px",
  },
  confirmBtn: {
    marginBottom: "20px",
    width: "100% !important",
    [theme.breakpoints.down(570)]: {
      marginLeft: "0px",
    },
  },
  name: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  formMiddle: {
    maxWidth: "100%",
    width: "315px",
  },
  formShort: {
    maxWidth: "100%",
    width: "189px",
  },
  marginRight24: {
    marginRight: "24px",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 800,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
  },
  nomalA: {
    display: "inline",
    textDecoration: "underline",
  },
}));
