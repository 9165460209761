import { getClinetId, getToken, getUserId } from "Utilities/appHelper";
import { REQUEST_API_URL } from "../config/constant";

export async function ApiScheduleUserRegistration(data: any) {
  const token = getToken()

  const response: any = await fetch(
    REQUEST_API_URL + "/scheduled_user_registrations",
    {
      method: "POST",
      mode: "cors",
      cache: "no-store",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      redirect: "error",
      referrerPolicy: "no-referrer-when-downgrade",
      body: JSON.stringify(data),
    }
  );

  return response.json();
}

export async function ApiScheduledUserRegistrations() {
  const token = getToken()
  const client_id = getClinetId();
  const userId = getUserId()
  const params = { userId: userId };
  const query = new URLSearchParams(params);

  const response: any = await fetch(
    REQUEST_API_URL + "/scheduled_user_registrations?client_id=" + client_id + `&${query}`,
    {
      method: "GET",
      mode: "cors",
      cache: "no-store",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      redirect: "error",
      referrerPolicy: "no-referrer-when-downgrade",
    }
  );

  return response.json();
}
